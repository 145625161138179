import React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";

export default ({ data }) => (
	<MDXRenderer>{data.slide.body}</MDXRenderer>
);

export const query = graphql`
	query SlideQuery($index: Int!) {
		slide(index: { eq: $index }) {
			body
			index
		}
	}
`;
